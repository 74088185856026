<template>
    <div class="container-fluid">
      <div
          class="page-header min-height-300 border-radius-xl mt-4 opacity-6 justify-content-center"
      >
        <img alt=""
             :src="require('@/assets/img/logo-black.svg')"
             class="w-50"
        >
            <span class="mask bg-gradient-success"></span>
        </div>
        <div class="card card-body mx-3 mx-md-4 mt-n6">
            <div class="row gx-4">
                <div class="col-auto">
                    <card-account-details-outline-icon size="70" />
                </div>
                <div class="col-auto my-auto">
                    <div class="h-100">
                        <h5 class="mb-1">{{ this.client.name }}</h5>
                        <p class="mb-0 font-weight-normal text-sm">
                            {{ this.client.title }}
                        </p>
                    </div>
                </div>
                <div
                    class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
                >
                    <div class="nav-wrapper position-relative end-0 d-flex">
<!--                        <material-button
                            class="my-4 mb-2"
                            variant="gradient"
                            color="danger"
                            type="submit"
                            >Деактивировать
                        </material-button>-->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="mt-3 row">
                    <div
                        class="col-12 col-md-6 col-xl-6 mt-md-0 mt-4 position-relative"
                    >
                        <div class="h-100">
                            <div class="p-3 pb-0 card-header"></div>
                            <div class="p-3 card-body">
                                <ul class="list-group">
                                    <li
                                        class="text-sm border-0 list-group-item ps-0"
                                    >
                                        <strong class="text-dark">ID:</strong>
                                        &nbsp; {{ client.id }}
                                    </li>
                                    <li
                                        class="text-sm border-0 list-group-item ps-0"
                                    >
                                        <strong class="text-dark"
                                            >Дата создания:</strong
                                        >
                                        &nbsp; {{ client.created }}
                                    </li>
                                    <li
                                        class="text-sm border-0 list-group-item ps-0"
                                    >
                                        <strong class="text-dark"
                                            >Дата редактирования:</strong
                                        >
                                        &nbsp; {{ client.updated }}
                                    </li>
                                </ul>
                                <div class="text-danger">
                                    {{ errorMessage }}
                                </div>
                                <form
                                    role="form"
                                    class="text-start mt-3"
                                    @submit.prevent="updateClient"
                                >
                                    <fieldset :disabled="isSaving">
                                        <div class="mb-3">
                                            <material-input
                                                id="client_name"
                                                type="text"
                                                label="Имя"
                                                name="client_name"
                                                isRequired="true"
                                                v-model="client.name"
                                                value="{{ client.name }}"
                                            />
                                        </div>
                                        <div class="my-4">
                                            <div
                                                class="d-flex flex-row align-items-center"
                                            >
                                                <material-button
                                                    variant="gradient"
                                                    color="success"
                                                    type="submit"
                                                    >Сохранить
                                                </material-button>
                                                <div
                                                    class="text-sm ms-2"
                                                    :class="
                                                        isEndSaved
                                                            ? ''
                                                            : 'd-none'
                                                    "
                                                >
                                                    <check-icon
                                                        fill-color="#4CAF50"
                                                        size="20"
                                                    />
                                                    Сохранено
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                        <hr class="vertical dark" />
                    </div>
                    <div
                        class="col-12 col-md-6 col-xl-6 mt-md-0 mt-4 position-relative"
                    >
                        <Licenses />
<!--                        <DevicesList />-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mask } from "vue-the-mask";

import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import Licenses from "./components/client/Licenses.vue";
// import ConnectionCodes from "./components/client/ConnectionCodes.vue";
// import DevicesList from "./components/client/DevicesList.vue";

import CardAccountDetailsOutlineIcon from "vue-material-design-icons/CardAccountDetailsOutline.vue";
import CheckIcon from "vue-material-design-icons/Check.vue";

import setTooltip from "@/assets/js/tooltip.js";
import { API_HOST } from "@/config";

export default {
    name: "profile-overview",
    data() {
        return {
            isSaving: false,
            isEndSaved: false,
            client: {},
        };
    },
    components: {
        // DevicesList,
        Licenses,
        MaterialInput,
        MaterialButton,

        CardAccountDetailsOutlineIcon,
        CheckIcon,
    },
    directives: { mask },
    methods: {
        loadClient() {
            axios
                .get(
                    API_HOST + "/v1/manager/client/" + this.$route.params.id,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization:
                                "Bearer " +
                                this.$store.getters.getTokenPair.access,
                        },
                    }
                )
                .then((response) => {
                    this.client = response.data;

                    this.$store.commit("setBreadcrumbs", [
                        "Клиенты",
                        `${this.client.name} (${this.client.id})`,
                    ]);

                    console.log(response.data);
                })
                .catch((error) => {
                    console.warn(error);
                });
        },
        updateClient() {
            this.$store.commit("setErrorMessage", "");
            axios
                .post(
                    API_HOST + "/v1/manager/client/" + this.$route.params.id,
                    this.client,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization:
                                "Bearer " +
                                this.$store.getters.getTokenPair.access,
                        },
                    }
                )
                .then((response) => {
                    console.info(response);
                    this.isEndSaved = true;

                    this.client = response.data;

                    setTimeout(() => {
                        this.isEndSaved = false;
                    }, 3000);
                })
                .catch((error) => {
                    console.warn(error);
                    if (error.response) {
                        this.$store.commit(
                            "setErrorMessage",
                            error.response.data.message
                        );
                    }
                });
        },
    },
    computed: {
        errorMessage() {
            return this.$store.getters.getErrorMessage;
        },
    },
    mounted() {
        this.loadClient();
        setTooltip();
    },
    beforeUnmount() {
        this.$store.commit("setBreadcrumbs", []);
    },
};
</script>

<style scoped></style>
