<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-12">
                <div class="card my-4">
                    <div
                        class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                    >
                        <div
                            class="bg-gradient-success shadow-success border-radius-lg py-3 d-flex justify-content-between"
                        >
                            <h6 class="text-white text-capitalize ps-3 pt-1">
                                Клиенты
                            </h6>

                            <router-link
                                to="/clients/client/new"
                                class="rounded-circle p-1 mx-3 bg-white"
                            >
                                <plus-icon size="30" fill-color="#49A3F1" />
                            </router-link>
                        </div>
                    </div>
                    <div class="card-body px-0 pb-2">
                        <div class="table-responsive p-0">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                      <th
                                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                                      >
                                        ID
                                        <material-input
                                            id="id"
                                            name="id"
                                            size="sm"
                                            class="mt-2 w-75"
                                            v-model="currentFilter.id"
                                            value="{{ currentFilter.id }}"
                                        />
                                      </th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                                        >
                                            Имя
                                            <material-input
                                                id="name"
                                                name="name"
                                                size="sm"
                                                class="mt-2 w-75"
                                                v-model="currentFilter.name"
                                                value="{{ currentFilter.name }}"
                                            />
                                        </th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                                        >
                                            Дата создания
                                        </th>
                                        <th
                                            class="text-secondary opacity-7"
                                        ></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="client in clients"
                                        :key="client.id"
                                    >
                                      <td>
                                        <div class="d-flex px-2 py-1">
                                          <div
                                              class="d-flex flex-column justify-content-center"
                                          >
                                            <h6 class="mb-0 text-sm">
                                              <span class="text-muted">#</span>&nbsp;{{ client.id }}
                                            </h6>
                                          </div>
                                        </div>
                                      </td>
                                        <td>
                                            <div class="d-flex px-2 py-1">
                                                <div
                                                    class="d-flex flex-column justify-content-center"
                                                >
                                                    <h6 class="mb-0 text-sm">
                                                        {{ client.name }}
                                                    </h6>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p
                                                class="text-xs font-weight-bold mb-0"
                                            >
                                                {{ client.created }}
                                            </p>
                                        </td>
                                        <td class="align-middle text-center">
                                            <div
                                                style="cursor: pointer"
                                                class="text-secondary font-weight-bold text-sm"
                                                data-toggle="tooltip"
                                                data-original-title="Edit user"
                                                @click="
                                                    this.$router.push({
                                                        path: `${
                                                            '/clients/client/' +
                                                            client.id
                                                        }`,
                                                    })
                                                "
                                            >
                                                <account-edit-icon size="30" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center">
            <material-pagination color="success" size="lg">
                <material-pagination-item
                    prev
                    :disabled="currentPage === 1"
                    :class="currentPage === 1 ? 'opacity-5' : ''"
                    @click="
                        currentPage > 1 ? loadClients(currentPage - 1) : false
                    "
                />
                <material-pagination-item
                    v-for="pageNum in countPages"
                    :key="pageNum"
                    :label="pageNum"
                    :active="pageNum === currentPage"
                    @click="
                        currentPage !== pageNum ? loadClients(pageNum) : false
                    "
                />
                <material-pagination-item
                    next
                    :disabled="currentPage === countPages"
                    :class="currentPage === countPages ? 'opacity-5' : ''"
                    @click="
                        currentPage < countPages
                            ? loadClients(currentPage + 1)
                            : false
                    "
                />
            </material-pagination>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import index from "vuex";
import { mask } from "vue-the-mask";

import MaterialPagination from "@/components/MaterialPagination.vue";
import MaterialPaginationItem from "@/components/MaterialPaginationItem.vue";
import MaterialInput from "@/components/MaterialInput.vue";

import PlusIcon from "vue-material-design-icons/Plus.vue";
import AccountEditIcon from "vue-material-design-icons/AccountEdit.vue";

import { debounce } from "../helpers/debounce.js";

import { API_HOST, CLIENT_COUNT_LIST } from "@/config";

export default {
    name: "tables",
    computed: {
        index() {
            return index;
        },
    },
    data() {
        return {
            clients: [],
            countPages: 1,
            currentPage: 1,
            currentFilter: {
                id: this.$route.query.id,
                name: this.$route.query.name,
            },
        };
    },
    directives: { mask },
    mounted() {
        this.loadClients(this.currentPage, this.currentFilter);
        this.$store.commit("setBreadcrumbs", ["Клиенты"]);
    },
    beforeUnmount() {
        this.$store.commit("setBreadcrumbs", []);
    },
    components: {
        MaterialInput,
        MaterialPagination,
        MaterialPaginationItem,
        PlusIcon,
        AccountEditIcon,
    },
    methods: {
        loadClients(page, filter) {
            this.currentPage = page;

            axios
                .get(API_HOST + "/v1/manager/client", {
                    params: {
                        page: page,
                        size: CLIENT_COUNT_LIST,
                        filter: filter,
                    },
                    headers: {
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + this.$store.getters.getTokenPair.access,
                    },
                })
                .then((response) => {
                    this.clients = response.data.list;
                    this.countPages = response.data.pages;

                    console.log(response.data);
                })
                .catch((error) => {
                    console.warn(error);
                });
        },
        setRouteQuery(property) {
            let query = { ...this.$route.query };
            if (this.currentFilter[property]) {
                this.$router.push({
                    query: {
                        ...query,
                        [property]: this.currentFilter[property],
                    },
                });
            } else {
                delete query[property];
                this.$router.push({
                    query: {
                        ...query,
                    },
                });
            }
        },
    },
    watch: {
        "$route.query": {
            handler: function () {
                this.loadClients(this.currentPage, this.currentFilter);
            },
            deep: true,
        },
        "currentFilter.id": debounce(function () {
            this.setRouteQuery("id");
        }, 500),
        "currentFilter.name": debounce(function () {
            this.setRouteQuery("name");
        }, 500),
    },
};
</script>
