<template>
    <div
        class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
        id="sidenav-collapse-main"
    >
        <ul class="navbar-nav">
            <li class="nav-item">
                <sidenav-collapse
                    url="#"
                    :aria-controls="''"
                    v-bind:collapse="false"
                    collapseRef="clients"
                    navText="Клиенты"
                >
                    <template v-slot:icon>
                        <account-group-icon />
                    </template>
                </sidenav-collapse>
            </li>
        </ul>
    </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";

import AccountGroupIcon from "vue-material-design-icons/AccountGroup.vue";

export default {
    name: "SidenavList",
    props: {
        cardBg: String,
    },
    data() {
        return {
            title: "Soft UI Dashboard PRO",
            controls: "dashboardsExamples",
            isActive: "active",
        };
    },
    components: {
        SidenavCollapse,
        AccountGroupIcon,
    },
};
</script>
