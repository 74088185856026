<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-12">
                <div
                    v-if="isEndCreate"
                    class="alert alert-success mb-5"
                    role="alert"
                >
                    Сертификат успешно выпущен!
                </div>

                <div
                    v-if="isCreateError"
                    class="alert alert-danger mb-5"
                    role="alert"
                >
                    {{ errorMessage }}
                </div>

                <div class="card my-4">
                    <div
                        class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                    >
                        <div
                            class="bg-gradient-success shadow-success border-radius-lg py-3 d-flex justify-content-between"
                        >
                            <h6 class="text-white ps-3 pt-1">
                                Сертификаты
                            </h6>

                            <div
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Нажмите чтобы добавить новый сертификат"
                                class="rounded-circle p-1 mx-3 bg-white cursor-pointer"
                                @click="createLicence"
                            >
                                <plus-icon size="30" fill-color="#66BB6A" />
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 pb-2 overflow-y-scroll" style="max-height: 400px;">
                        <div class="table-responsive p-0">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                                        >
                                            Хэш
                                        </th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                                        >
                                            Статус
                                        </th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                                        >
                                            Дата окончания
                                        </th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                                        >
                                            Дата создания
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="cert in certs"
                                        :key="cert.hash"
                                        :class="cert.status === 'active' ? `text-success` : ``"
                                    >
                                      <td>
                                        <p class="text-xs font-weight-bold mb-0">
                                          {{ cert.hash || "-" }}
                                        </p>
                                      </td>
                                      <td>
                                        <p class="text-xs font-weight-bold mb-0">
                                          {{ status [cert.status] || "-" }}
                                        </p>
                                      </td>
                                      <td>
                                        <p class="text-xs font-weight-bold mb-0">
                                          {{ cert.expire || "-" }}
                                        </p>
                                      </td>
                                      <td>
                                        <p class="text-xs font-weight-bold mb-0">
                                          {{ cert.created || "-" }}
                                        </p>
                                      </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PlusIcon from "vue-material-design-icons/Plus.vue";
import axios from "axios";
import { API_HOST } from "@/config";

export default {
    name: "Licenses",
    components: {
        PlusIcon,
    },
    data() {
        return {
            status: {
                inactive: "Неактивный",
                active: "Активный",
                revoked: "Отозван",
            },
            certs: [],
            isEndCreate: false,
            isCreateError: false,
        };
    },
    methods: {
        getCerts() {
            axios
                .get(
                    API_HOST + "/v1/manager/client/" + this.$route.params.id + "/licenses",
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + this.$store.getters.getTokenPair.access,
                        },
                    }
                )
                .then((response) => {
                    this.certs = response.data.certs;
                })
                .catch((error) => {
                    console.warn(error);
                });
        },
        createLicence() {
            axios
                .put(
                    API_HOST + "/v1/manager/client/" + this.$route.params.id + "/licenses",
                    "{}",
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + this.$store.getters.getTokenPair.access,
                        },
                    }
                )
                .then(() => {
                    this.getCerts();
                    this.isEndCreate = true;

                    setTimeout(() => {
                        this.isEndCreate = false;
                    }, 3000);
                })
                .catch((error) => {
                    console.warn(error);
                    this.isCreateError = true;

                    setTimeout(() => {
                        this.isCreateError = false;
                    }, 3000);
                });
        },
    },
    computed: {
        errorMessage() {
            return this.$store.getters.getErrorMessage;
        },
    },
    mounted() {
        this.getCerts();
    },
};
</script>
